import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Content from "./components/Content/Content";
import Navigation from "./components/Navigation/Navigation";

function App() {
  return (
    <div className="wrapper">
      <Content />
      <Navigation />
    </div>
  );
}

export default App;
