import { Alert, Divider } from "antd";
import React from "react";

const Contacts = () => {
  return (
    <div>
      <h1>Контакти</h1>
      <Divider orientation="left">Робочі години</Divider>
      <p>Пн-Пт з 9:00-16:00 (обід з 12:00-12:45)</p>
      <Divider orientation="left">Адреса</Divider>
      <p>
        Київська область, Фастівський р-н (Києво-Святошинський район), м.Боярка,
        вулиця Богдана Хмельницького, 67А. Вхід з двору.
      </p>
      <Divider orientation="left">Контактна особа</Divider>
      <p>Павлюк Михайло Анатолійович</p>
      <Divider orientation="left">Контактні дані</Divider>
      <p>
        Тел.: <a href="tel:+380986647817">+38 (098) 66 47 817</a>
      </p>
      <p>
        e-mail: <a href="mailto:pavlukma1@ukr.net">pavlukma1@ukr.net</a>
      </p>
      <Alert
        message=" Зв'язок може бути відсутнім (в зв'язку з перебоями електроенергії)!
        Пишіть в мессенджер."
        type="info"
        showIcon
      />
      <p>
        Viber/WhatsApp: <a href="tel:+380984354482">+38 (098) 435 44 82</a>
      </p>
    </div>
  );
};

export default Contacts;
