import { Alert } from "antd";
import React from "react";

const Region = () => {
  return (
    <div>
      <h1>Регіон в якому ми працюємо</h1>
      <br />
      <Alert
        message="Бучанський, Фастівський район у виконанні робіт 100%. Виконання робіт в інших районах уточнюйте."
        type="info"
        showIcon
      />
      <br />
      <iframe
        src="https://www.google.com/maps/d/embed?mid=1VbX-SRVj9JigmMDNB34VglVkUfEWRWc&ehbc=2E312F"
        title="Регіон в якому ми працюємо"
        width={window.innerWidth - 500}
        height={window.innerHeight - 250}
        frameBorder={0}
      />
    </div>
  );
};

export default Region;
