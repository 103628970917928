import { MouseEvent, useState } from "react";
import logo from "../../assets/images/logo.png";
import menuIcon from "../../assets/images/menu.png";
import close from "../../assets/images/close.png";
import { useLocation, useNavigate } from "react-router-dom";
import isMobile from "../../helpers/isMobile";

import styles from "./styles.module.scss";

const elements: any = {
  about: "Будівництво будівель та споруд",
  region: "Регіон, в якому ми працюємо",
  contacts: "Контакти",
  // gallery: "Галерея",
  // law: "Законодавство",
};

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(!isMobile());

  const handleNavigate = (e: MouseEvent<HTMLUListElement>) => {
    const rout = (e.target as HTMLUListElement).id;
    navigate(rout);

    if (isMobile()) {
      setVisible(false);
    }
  };

  if (!visible)
    return (
      <div className={styles.menuIcon}>
        <img src={menuIcon} alt="open menu" onClick={() => setVisible(true)} />
      </div>
    );

  return (
    visible && (
      <div
        className={[
          isMobile() ? styles.wrapperMobile : "",
          styles.wrapper,
        ].join(" ")}
      >
        {isMobile() && (
          <img
            src={close}
            alt="close menu"
            className={styles.closeIcon}
            onClick={() => setVisible(false)}
          />
        )}
        <img src={logo} alt="logo" className={styles.logo} />
        <ul onClick={handleNavigate}>
          {Object.keys(elements).map((id) => (
            <li
              key={id}
              id={id}
              className={location.pathname.includes(id) ? styles.selected : ""}
            >
              {elements[id]}
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default Navigation;
