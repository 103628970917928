import { Alert, Divider, List } from "antd";
import React, { ReactNode } from "react";

import styles from "./styles.module.scss";

const priceData = [
  "Орієнтовна ціна для будинку площею від 100-150м2, ціна за 1м2-500$. В цю вартість входить (робота, матеріал): проект будинку(однотипний), фундамент, коробка(стіни), вікна та двері вхідні, криша(перекритя);",
  "Можете використати штучний інтелект для створення плану будівлі, а ми по ньому збудуємо. Посилання https://chat.openai.com При умові що не буде інженерних порушень;",
  "Орієнтовна ціна проекту (будівельний паспорт) для будинку площею від 100-150м2, ціна від 70000грн. Проект розробляється індивідуально під замовника. Це при умові, що наш проект не влаштовує (однотипний).",
];

const whatWeDoData = [
  "Розробки проектно-конструкторської, проектно-технологічної документації;",
  "Виконання робіт з прив'язки будівель до будівельних майданчиків;",
  "Обробки проектно-кошторисної документації;",
  "Забезпечення правильного використання будівельних матеріалів, конструкцій, виробів і напівфабрикатів;",
  "Забезпечення будівельно-монтажних робіт машинами, механізмами та транспортом;",
  "Забезпечення організаційно-технологічних заходів будівельного виробництва;",
  "Забезпечення виробничої дисципліни на ділянці і створення сприятливих психологічних умов стимулювання праці;",
  "Забезпечення охорони праці, техніки безпеки і виробничої санітарії на будівництві;",
  "Догляд за станом будівель і споруд;",
  "Проведення ремонтних робіт;",
  "Здійснення контролю за ходом будівництва, правильності виконання робіт на об'єкті;",
  "Здійснення контролю за якістю виконання будівельно-монтажних робіт.",
];

const About = () => {
  return (
    <div>
      <h1>Будівництво будівель та споруд</h1>
      <Divider orientation="left">Ціни</Divider>
      <List
        size="large"
        bordered
        dataSource={priceData}
        renderItem={(item: ReactNode) => <List.Item>{item}</List.Item>}
      />
      <Divider orientation="left">Виконуємо роботи</Divider>
      <List
        size="large"
        bordered
        dataSource={whatWeDoData}
        renderItem={(item: ReactNode) => <List.Item>{item}</List.Item>}
      />
      {/* <Alert
        className={styles.info}
        message="Як правило, роботи щодо спорудження інженерних мереж в будинку пов’язані
        з купою складнощів – «битви» з обленерго і облгазами, чвари тепловиків з
        електриками і сантехніками, який і куди котел, де взяти якісну воду,
        куди і як скинути каналізаційні стоки, вентиляція чи кондиціонування, як
        зекономити на енергоресурсах під час подальшої експлуатації – і це
        тільки мала доля запитань, які виникають під час будівництва. Якщо у вас
        немає бажання постаріти років на 20, заробити виразку шлунку та почати
        клацати зубами при слові «облгаз» - то звертайтесь до нас! Спеціалісти
        нашої компанії допоможуть у вирішенні цих вельми неприємних питань."
        type="info"
        showIcon
      /> */}
    </div>
  );
};

export default About;
