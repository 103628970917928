import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import About from "../../assets/pages/About/About";
import Region from "../../assets/pages/Region/Region";
import Contacts from "../../assets/pages/Contacts/Contacts";
import Gallery from "../../assets/pages/Gallery/Gallery";
import Law from "../../assets/pages/Law/Law";

const Content = () => {
  const location = useLocation();

  const Page = () => {
    switch (location.pathname) {
      case "/":
      case "/about":
        return <About />;
      case "/region":
        return <Region />;
      case "/contacts":
        return <Contacts />;
      case "/gallery":
        return <Gallery />;
      case "/law":
        return <Law />;
      default:
        return <>Page not found</>;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.pageWrapper}>
        <Page />
      </div>
    </div>
  );
};

export default Content;
